/* doctor service.module.css */

.container {
  width: 100%;
  height: 1000rem; /* 100%视窗高度 */
  background-image: url("../../assets/doctor_service_bg.png"); /* 替换成你的背景图片路径 */
  background-size: cover; /* 背景图片等比例缩放以覆盖整个容器 */
  background-position: center; /* 图片在容器中居中显示 */
  display: flex; /* 使内容垂直和水平居中 */
  flex-direction: column; /* 设置主轴为垂直方向 */
  overflow: hidden;
  .topColumn {
    display: flex;
    margin-top: 333rem;
    font-size: 38rem;
    .leftColumn {
      width: 800rem;
      margin-left: 240rem;
      .columnTitle {
        font-size: 80rem;
        font-weight: 500;
        margin-bottom: 30rem;
      }
      .columnInfo {
        margin: 10rem 0 30rem 0;
      }
      .findMore {
        width: 168rem;
        height: 60rem;
        background: #1d7bff;
        border-radius: 40rem;
        color: #fff;
        font-size: 20rem;
        line-height: 28rem;
        border: none;
        cursor: pointer;
      }
    }
    .rightColumn {
      width: 619rem;
      height: 412rem;
      margin-left: 35rem;
      img {
        width: 619rem;
        height: 412rem;
      }
    }
  }
}
