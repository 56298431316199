/* #app_div {
  zoom: 0.7;
} */
/* @media only screen and (max-width: 768px) {
    html, body {
      overflow-x: auto;
    }
  }
   */
   html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  