/* Header.module.css */

.footer {
    width: 100%;
    height: 80rem;
    /* background: #FFFFFF; */
    /* opacity: 0.05; */
    /* position: fixed; */
    /* bottom: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 18rem;
    background-color: #1F2430;
  }