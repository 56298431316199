/* home.module.css */

.container {
  width: 100%;
  height: 1000rem; /* 100%视窗高度 */
  background-image: url("../../assets/homebg.png"); /* 替换成你的背景图片路径 */
  background-size: cover; /* 背景图片等比例缩放以覆盖整个容器 */
  background-position: center; /* 图片在容器中居中显示 */
  display: flex; /* 使内容垂直和水平居中 */
  flex-direction: column; /* 设置主轴为垂直方向 */
  color: #fff; /* 设置文本颜色为白色，以确保文本可见 */
  overflow: hidden;
  position: relative;
  .topColumn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 244rem;
    .leftColumn {
      font-size: 80rem;
      min-width: 800rem;
      margin-left: 240rem;
      p {
        font-size: 38rem;
      }
    }
    .rightColumn {
      margin-right: 200rem;
      margin-left: 146rem;
      img {
        width: 494rem;
        height: auto;
      }
    }
  }
  .bottomColumn {
    display: flex;
    width: 100%;
    //   height: 211rem;
    // margin-top: 75rem;
    position: absolute;
    bottom: 0;
    .bottomItem {
      width: 360rem;
      height: 211rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16rem;
      padding: 0 58rem;
      text-align: center;
      .bottomItemTitle {
        font-size: 24rem;
        margin-bottom: 17rem;
      }
    }
    .emptyBottomItem {
      width: 240rem;
      height: 211rem;
    }
  }
}
