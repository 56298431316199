/* DigitalMarketing.module.css */

.container {
  width: 100%;
  height: 1000rem; /* 100%视窗高度 */
  background-image: url("../../assets/homebg.png"); /* 替换成你的背景图片路径 */
  background-size: cover; /* 背景图片等比例缩放以覆盖整个容器 */
  background-position: center; /* 图片在容器中居中显示 */
  display: flex; /* 使内容垂直和水平居中 */
  flex-direction: column; /* 设置主轴为垂直方向 */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .topColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 207rem;
    font-size: 80rem;
    color: #fff;
    span {
      font-size: 38rem;
      margin: 30rem 0 3rem 0;
    }
    .findMore {
      width: 168rem;
      height: 60rem;
      background: #1d7bff;
      border-radius: 40rem;
      color: #fff;
      font-size: 20rem;
      line-height: 28rem;
      border: none;
      cursor: pointer;
      margin-top: 39rem;
    }
  }
  .bottomColumn {
    display: grid;
    grid-template-rows: repeat(2, 160rem); /* 定义两行，每行高度为50rem */
    grid-template-columns: repeat(2, 660rem); /* 定义两列，每列宽度为50rem */
    gap: 40rem; /* 定义方块之间的间距 */
    margin-top: 60rem;
    .bottomItem {
      width: 540rem;
      height: 110rem;
      background: #ffffff;
      border-radius: 20rem;
      display: flex;
      align-items: center;
      font-size: 22rem;
      margin-right: 20rem;
      padding: 30rem 60rem;
      img {
        width: 100rem;
        height: 100rem;
        margin-right: 30rem;
      }
      .bottomItemText {
        display: flex;
        flex-direction: column;
        .bottomItemTitle {
          font-size: 26rem;
          margin: 0 0 5rem 0;
          font-weight: 500;
          color: #262626;
        }
        .bottomItemContent {
          margin-bottom: 3rem;
          font-weight: 500;
          color: #262626;
        }
        .bottomItemInfo {
          font-size: 18rem;
          color: #9a9a9a;
          line-height: 30rem;
        }
      }
    }
  }
}
