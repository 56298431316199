/* about.module.css */

.container {
  background: url('../../assets/about_bg.png') no-repeat center center/100% 100%;
  overflow: hidden;
  padding-top: 207rem;
  .content {
    width: 1380rem;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .title {
      width: 860rem;
      height: 112rem;
      font-size: 80rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 112rem;
      margin-bottom: 70rem;
    }
    .paragraph {
      width: 1380rem;
      height: 296rem;
      font-size: 26rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 37rem;
      .paragraph_title {
        font-size: 30rem;
        margin-bottom: 10rem;
      }
      .paragraph_p {
        margin-bottom: 30rem;
      }
    }
    .img_qualifications {
      width: 1324rem;
      height: 736rem;
      margin: 60rem 0 103rem 0;
    }
  }

  .img_cooperate_box {
    font-size: 0;
    position: relative;
    .img_cooperate {
      width: 1920rem;
    }

    .text_wrap {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
    }
  }
}
