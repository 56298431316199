/* Header.module.css */
.header {
  width: 100%;
  min-width: 1080rem;
  height: 130rem;
  background: rgba(255, 255, 255, 0.05);
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10vw;
    img {
      width: 177rem;
      height: 54rem;
    }
  }
  .menu {
    display: flex;
    .menuItem {
      margin-right: 100rem;
      text-decoration: none!;
      display: flex;
      align-items: center;
      min-width: 150rem;
      .link {
        text-decoration: none;
        font-size: 24rem;
        font-weight: 400;
        line-height: 33rem;
        cursor: pointer;

        &.white {
          color: #fff;
        }
      }
      .link:visited {
        color: #fff; /* 点击过的链接颜色仍为白色 */
      }
      .activeLink {
        color: #1d7bff !important; /* 设置激活状态下的颜色为蓝色 */
      }
    }
    .menuItem:hover {
      text-decoration: none;
    }
  }
}
.header2 {
  background-color: #fff;
  .link {
    color: #000 !important;
  }
  .link:visited {
    color: #000; /* 点击过的链接颜色仍为黑色 */
  }
  .activeLink {
    color: #1d7bff !important; /* 设置激活状态下的颜色为蓝色 */
  }
}
