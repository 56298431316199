/* DoctorGroup.module.css */

.container {
  width: 100%;
  height: 1000rem; /* 100%视窗高度 */
  background-image: url("../../assets/doctor_service_bg.png"); /* 替换成你的背景图片路径 */
  background-size: cover; /* 背景图片等比例缩放以覆盖整个容器 */
  background-position: center; /* 图片在容器中居中显示 */
  display: flex; /* 使内容垂直和水平居中 */
  flex-direction: column; /* 设置主轴为垂直方向 */
  overflow: hidden;
  .topColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 207rem;
    font-size: 80rem;
    span {
      font-size: 38rem;
    }
    .findMore {
      width: 168rem;
      height: 60rem;
      background: #1d7bff;
      border-radius: 40rem;
      color: #fff;
      font-size: 20rem;
      line-height: 28rem;
      border: none;
      cursor: pointer;
      margin-top: 39rem;
    }
  }
  .bottomColumn {
    display: flex;
    width: 100%;
    //   height: 211rem;
    // margin-bottom: 80rem;
    margin-top: 60rem;
    justify-content: center;
    .bottomItem {
      min-width: 260rem;
      height: 280rem;
      background: #ffffff;
      border-radius: 20rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22rem;
      text-align: center;
      margin-right: 20rem;
      img {
        width: 60rem;
        height: 60rem;
      }
      .bottomItemTitle {
        font-size: 26rem;
        margin: 21rem 0 17rem 0;
        font-weight: 500;
      }
      .bottomItemContent {
        margin-bottom: 20rem;
      }
      .bottomItemType {
        font-size: 22rem;
        color: #1d7bff;
        line-height: 30rem;
      }
    }
  }
}
